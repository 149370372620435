<template>
    <form @submit.prevent="search" class="filter">
        <b-row>
            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t("DISTRIBUTION")}}</label>
                <app-select :options="distributionOptions" v-model="distributionOptionValue"></app-select>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t("_DATE")}}</label>
                <app-select :options="dateOptions" v-model="dateOptionValue"></app-select>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t("FROM")}}</label>
                <app-date-picker :disabled="!dateOptionValue" v-model="fromDate" :max-date="toDate"></app-date-picker>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t("TO")}}</label>
                <app-date-picker :disabled="!dateOptionValue" v-model="toDate" :min-date="fromDate" :from-date="fromDate"></app-date-picker>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{$t("OWNER_INVOICE_CREATED")}}</label>
                <app-select :options="ownerInvoiceOptions" v-model="ownerInvoiceOptionValue"></app-select>
            </b-col>

            <b-col xl="2" lg="4" md="6" class="mb-3">
                <label>{{ $t('INVOICE_STATUS') }}</label>
                    <app-select v-model="invoiceStatus"
                                :disabled="!(ownerInvoiceOptionValue === '1')"
                                :options="invoiceStatusList"
                                text-field="label"
                                mode="multiselect">
                    </app-select>
            </b-col>

            <b-col xl="4" lg="4" md="6" class="mb-3">
                <app-button-submit class="filter-submit-wrapper" :inline="true" :disabled="(loading || (!fromDateInput || !toDateInput || !invoiceInput || !distributionInput))" :loading="loaderSubmit" variant="primary"
                                   button_type="search">{{ $t("SEARCH") }}
                </app-button-submit>
                <app-button-reset @click="resetForm" :disabled="loading" :loading="loaderReset" :inline="true" class="pl-2 ml-2"></app-button-reset>
                <app-button-submit class="pl-2 ml-2" :inline="true" :disabled="(loading || (!fromDateInput || !toDateInput || !invoiceInput || !distributionInput))" :loading="loaderExport" variant="primary"
                                   @click="downloadXls()"> {{ $t("EXPORT") }}
                </app-button-submit>
<!--                :loading="downloadXlsLoading.hasOwnProperty(data.item.id) ? downloadXlsLoading[data.item.id] : false"-->
            </b-col>
        </b-row>
    </form>
</template>

<script>
import AppDatePicker from "@/components/app/datetime/AppDatePicker.vue";
import AppSelect from "@/components/app/AppSelect/AppSelect";
import AppButtonSubmit from "@/components/app/AppButton/AppButtonSubmit";
import AppButtonReset from "@/components/app/AppButton/AppButtonReset";
import AppButton from "@/components/app/AppButton/AppButton.vue";
import {toast} from "@/shared/plugins/toastr";
import {payoutReportDownloadXls} from "@/services/finance/payout_report";
export default {
    name: "PayoutFilter",
    props:{
        loading:{
            type:Boolean,
            default:false
        }
    },
    components: {
        AppButton,
        AppDatePicker, AppButtonReset, AppButtonSubmit, AppSelect
    },
    data() {
        return {
            dateOptions: [
                { id: 'check_out', name: this.$t('Check out') }
            ],
            distributionOptions: [
                { id: '5', name: this.$t('AirBnB') },
                { id: '4', name: this.$t('Booking.com') }
            ],
            ownerInvoiceOptions: [
                { id: '1', name: this.$t('Yes') },
                { id: '0', name: this.$t('No') }
            ],
            ownerInvoiceOptionValue: null,
            distributionOptionValue: null,
            dateOptionValue: null,
            dateObject: {},
            fromDate: null,
            toDate: null,
            fromDateInput: false,
            toDateInput: false,
            loaderReset: false,
            loaderSubmit: false,
            loaderExport: false,
            reset: 0,
            invoiceStatus: [],
            invoiceStatusList: [
                { id: 1, label: 'Created'},
                { id: 2, label: 'Processing'},
                { id: 3, label: 'Processed'},
                { id: 4, label: 'Completed'},
                { id: 5, label: 'Paid'},
            ],
            invoiceInput: false,
            distributionInput: false,
        }
    },
    computed:{
        company() {
            return this.$store.getters['user/getCurrentCompany']
        },
    },
    methods: {
        search(){
            this.reset = 0
            this.$emit('search',{...this.dateObject,...{distribution: this.distributionOptionValue, invoice_status: this.invoiceStatus, owner_invoice_created: this.ownerInvoiceOptionValue}})
        },
        // Resets the form
        resetForm() {
            this.reset =1
            if (Object.keys(this.$route.query).length > 0) {
                this.$router.replace({query: {}})
            }
            this.dateObject = {}
            this.distributionOptionValue = null
            this.ownerInvoiceOptionValue = null,
            this.dateOptionValue = null
            this.fromDate = null
            this.toDate = null
            this.invoiceStatus = [],
            this.invoiceInput = false,
            this.distributionInput = false,
            this.fromDateInput = false,
            this.toDateInput = false
        },
        downloadXls() {
            this.loaderExport = true;
            let req = {
                ...this.dateObject,
                ...{
                    company: this.company.id,
                    owner_invoice_created: this.ownerInvoiceOptionValue,
                    distribution: this.distributionOptionValue,
                    invoice_status: this.invoiceStatus
                }
            }
            payoutReportDownloadXls(req).then((response) => {
                let blob = new Blob([response], {type: 'application/xlsx'});
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Payout Report (${this.distributionOptionValue}) ${this.fromDate} - ${this.toDate}.xlsx`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            },
                (error) => {
                    toast({
                        'title': this.$t('Bad Request'),
                        'message': this.$t(error.response.data),
                        'type': 'error',
                        'timeout': 4000
                    })
                }).finally(() => {
                this.loaderExport = false;
            })
        },
    },
    watch: {
        fromDate(value) {
            if (this.dateOptionValue) {
                this.dateObject[this.dateOptionValue + '_from_date'] = value
            }
            if(value){
                this.fromDateInput = true;
            }
            else{
                this.fromDateInput = false;
            }
        },
        toDate(value) {
            if (this.dateOptionValue) {
                this.dateObject[this.dateOptionValue + '_to_date'] = value
            }
            if(value){
                this.toDateInput = true;
            }
            else{
                this.toDateInput = false;
            }
        },
        dateOptionValue(value, oldValue) {
            if (oldValue !== undefined) {
                this.fromDate = null
                this.toDate = null
                this.dateObject = {}
            }
        },
        distributionOptionValue(value) {
            if(value){
                this.distributionInput = true;
            }
        },
        ownerInvoiceOptionValue(value) {
            if(value){
                this.invoiceInput = true,
                this.invoiceStatus = []
            }
        },
        loading: {
            handler(value) {
                if (this.reset) {
                    this.loaderReset = value;
                } else {
                    this.loaderSubmit = value;
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
